import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LightingGalleryContent from "../components/GalleryContent/LightingGalleryContent"

const HeaderLighting = () => (
  <Layout>
    <SEO title="Lighting" />
    <h1>Lighting</h1>
    <LightingGalleryContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default HeaderLighting
